<div class="table100">
    <table id="customers">
        <tr>
            <th>Nombre</th>
            <th>Telefono</th>
            <th>Estatus</th>
            <th>Acción</th>

        </tr>
        <tr *ngFor="let support of supports; index as i; first as isFirst">
            <td>{{support.nombre}}</td>
            <td>{{support.descripcion}}</td>
            <td *ngIf="support.status == true">Activo</td>
            <td *ngIf="support.status == false">Incativo</td>
            <td class="align-icon">
                <ng-template #edit>Editar soporte</ng-template>
                <ng-template #del>Eliminar soporte</ng-template>
                <i [ngbTooltip]="edit" (click)="update(support.key,supports[i])" class="fa fa-pencil-square-o"
                    aria-hidden="true"></i>
                <i [ngbTooltip]="del" (click)="delete(support.key)" class="fa fa-trash" aria-hidden="true"></i>
            </td>
        </tr>


    </table>
    <br>
    <div class="col-sm-12">
        <button routerLink="/soporte-form" class="button">
            Nuevo
            <div class="button__horizontal"></div>
            <div class="button__vertical"></div>
        </button>
    </div>
</div>