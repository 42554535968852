<div class="container-fluid">
    <div class="row">
        <div class="col-12 p-0">
            <table class="table table-striped text-center">
                <thead>
                    <tr class="bg-secondary">
                        <th class="align-middle">Reporte</th>
                        <th class="align-middle">Tipo de reporte</th>
                        <th class="align-middle">Departamento / Área</th>
                        <th class="align-middle">Fecha de alta</th>
                        <!-- <th>Fecha de cierre</th> -->
                        <th class="align-middle">Estatus</th>
                        <th class="align-middle">Acción</th>
                    </tr>
                </thead>
                <tbody *ngIf="!loading">
                    <tr *ngFor="let report of reports; index as i; first as isFirst" (click)="openReportDetail(report)">
                        <td class="align-middle">{{report.servicio}}</td>
                        <td class="align-middle">{{report.isArea ? 'Área' : 'Departamento'}}</td>
                        <td class="align-middle">{{report.departamento}}</td>
                        <td class="align-middle">{{report.fechaAlta.seconds*1000 | date: 'EEEE, MMMM d, y HH:mm'}}</td>
                        <td class="align-middle">{{report.status == 0 ? 'Pendiente' : report.status == 1 ? 'En proceso' : 'Concluido'}}</td>
                        <td class="align-middle">
                            <ng-template #tipContentOption>Detalles</ng-template>
                            <button class="btn" [ngbTooltip]="tipContentOption">
                                <i class="material-icons">more_vert</i>
                            </button>
                        </td>
                    </tr>

                    <tr *ngIf="reports.length == 0">
                        <td colspan="6">No hay reportes registrados</td>
                    </tr>
                </tbody>
                <tbody *ngIf="loading">
                    <tr>
                        <td colspan="6">Cargando...</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
