import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ModalDetalleReporteComponent } from '../modal-detalle-reporte/modal-detalle-reporte.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../../../services/toast/toast.service';

@Component({
  selector: 'app-lista-gestion-seguridad',
  templateUrl: './lista-gestion-seguridad.component.html',
  styleUrls: ['./lista-gestion-seguridad.component.css']
})
export class ListaGestionSeguridadComponent implements OnInit {

  @Input() reports: Array<any>;
  @Input() loading: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal, 
    public toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  openReportDetail(report){
    console.log(report);
    
    const modalRef = this.modalService.open(ModalDetalleReporteComponent, {
      // size: 'lg'
    })
    
    modalRef.componentInstance.report = report;
  }

}
