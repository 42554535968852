import { Component, OnInit } from '@angular/core';
import { FirestoreService } from '../../../services/firestore/firestore.service';
import { map, flatMap } from 'rxjs/operators';
import { ActivatedRoute, Params } from '@angular/router';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import {
  NgbModal, 
  ModalDismissReasons, 
  NgbDate, 
  NgbCalendar, 
  NgbDateParserFormatter
} from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-reservaciones-historial',
  templateUrl: './reservaciones-historial.component.html',
  styleUrls: ['./reservaciones-historial.component.css']
})
export class ReservacionesHistorialComponent implements OnInit {

  public reservations = [];
  public idCondominio = localStorage.getItem('condominio').replace(/['"]+/g, '');
  public idArea = this.currentRoute.snapshot.params.idArea;
  pageSize: number = 20;
  page = 1;
  isEditar;
  public isEliminar;
  isEscribir;
  templateToExcel: string[][] = [];
  data = [];
  public allS = [];
  public kindOfUser;
  public currentBook: any;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  public selected: boolean = false;

  constructor(
    private firestoreService: FirestoreService,
    private currentRoute: ActivatedRoute,
    private router: Router,
    private firestore: AngularFirestore,
    private modalService: NgbModal,
    private calendar: NgbCalendar, 
    public formatter: NgbDateParserFormatter
  ) { 
    // this.fromDate = calendar.getToday();
    // this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit(): void {
    this.isEditar = localStorage.getItem('isEditar');
    this.isEscribir = localStorage.getItem('isEscribir');
    this.isEliminar = localStorage.getItem('isEliminar');
    this.kindOfUser = localStorage.getItem('userOper');
    // this.getReservations(this.currentRoute.snapshot.params.idArea);
  }

  /*
   ██████╗ ███████╗████████╗██████╗ ███████╗███████╗███████╗██████╗ ██╗   ██╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗███████╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██╔════╝██╔════╝██╔════╝██╔══██╗██║   ██║██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
  ██║  ███╗█████╗     ██║   ██████╔╝█████╗  ███████╗█████╗  ██████╔╝██║   ██║███████║   ██║   ██║██║   ██║██╔██╗ ██║███████╗
  ██║   ██║██╔══╝     ██║   ██╔══██╗██╔══╝  ╚════██║██╔══╝  ██╔══██╗╚██╗ ██╔╝██╔══██║   ██║   ██║██║   ██║██║╚██╗██║╚════██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████║███████╗██║  ██║ ╚████╔╝ ██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║███████║
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝╚═╝  ╚═╝  ╚═══╝  ╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝

  */
  /**
   * FUNCTION getReservations
   * 
   * @param idArea: string
   */
  getReservations(idArea: string) {
    // console.log(idArea);

    // console.log(this.fromDate);
    // console.log(this.toDate);
    
    let _fromDate = new Date(`${this.fromDate.month}/${this.fromDate.day}/${this.fromDate.year}`);
    let _toDate = new Date(`${this.toDate.month}/${this.toDate.day}/${this.toDate.year}`);

    // console.log(_fromDate);
    // console.log(_toDate);
    
    
    var query = this.firestore.collection(`/Condominios/${this.idCondominio}/Reservaciones`, ref => 
      ref.where('idArea', '==', idArea)
      .where('fechaInicio', '>=', _fromDate)
      .where('fechaInicio', '<=', _toDate)
      .orderBy('fechaInicio', 'desc')
    )
    .snapshotChanges()
    .subscribe((reservs) => {
      this.reservations = reservs.map( c => ({id: c.payload.doc.id, ...c.payload.doc.data() as any}));

      console.log(this.reservations);
      
    });
      // .snapshotChanges()
      // .pipe(map(docs =>
      //   docs.map(
      //     c => ({ id: c.payload.doc.id, ...c.payload.doc.data() as DocumentChangeAction<any> })
      //   )
      // ))
      // .subscribe(reser => {
      //   console.log("all",reser);
      //   this.reservations = reser;
      // });

    // console.log(this.reservations);

  }

  delete(key) {
    console.log(key);
    if (confirm("¿Desea borrar reservación?")) {
      this.firestoreService
        .deleteReservation(key).then(function () {
          // this.getReservations(this.idTower)
        })
        .catch(err => console.log(err));
    }
    else {

    }
  }

  cancel(key) {
    // console.log(key);
    if (confirm("¿Desea cancelar reservación?")) {
      this.firestore.collection(`/Condominios/${this.idCondominio}/Reservaciones/`)
      .doc(`${key}`).update({
        status: false
      })
    }
    else {

    }
  }

  public excelHeaders: string[] = ["Torre", "Departamento", "Area", "Fecha inicio", "Fecha Fin"];

  public arraryT = [];
  exportTemplateAsExcel() {
    for (var i = 0; i < this.reservations.length; i++) {
      if (i == 0) {
        this.templateToExcel.push(
          this.excelHeaders
        )
      }

      this.data = [
        this.reservations[i].torre,
        this.reservations[i].departamento,
        this.reservations[i].area,
        new Date(this.reservations[i].fechaInicio.seconds * 1000),
        new Date(this.reservations[i].fechaFin.seconds * 1000),
      ]

      this.templateToExcel.push(
        this.data
      )

    }
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.templateToExcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reservaciones');
    XLSX.writeFile(wb, "test" + ".xlsx");
  }

  showDetails(reservacion, content){
    // console.log(reservacion);
    this.currentBook = reservacion;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'});
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if(this.fromDate && this.toDate){
      // console.log('Checar fecha');
      this.selected = true;
      this.getReservations(this.currentRoute.snapshot.params.idArea);
    }

    // console.log('onDateSelection');
    
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
}
